<template>
  <Panel
      :title="title"
      :loading="loading">
    <template
        v-if="$slots.title"
        #title>
      <slot name="title" />
    </template>
    <template
        v-if="$slots.actions"
        #actions>
      <slot name="actions" />
    </template>

    <InsetPane class="inset-panel__inset">
      <slot />
    </InsetPane>
  </Panel>
</template>
<script>
import Panel from './Panel.vue';
import InsetPane from './InsetPane.vue';

export default {
  components: { InsetPane, Panel },
  props: { ...Panel.props },
};
</script>
