<template>
  <Pane class="dialog-action-pane">
    <Pane class="dialog-action-pane__main">
      <slot name="main">
        <Scrollable
            v-if="scrollable">
          <slot />
        </Scrollable>
        <slot v-else />
      </slot>
    </Pane>
    <slot name="footer">
      <footer
          v-if="!!$slots.actions || !!$slots.buttons || !!$slots.footerContent"
          class="dialog-action-pane__footer">
        <slot name="footerContent">
          <div
              v-if="$slots.actions"
              class="dialog-action-pane__actions">
            <slot name="actions" />
          </div>
          <div
              v-if="$slots.buttons"
              class="dialog-action-pane__buttons">
            <slot name="buttons" />
          </div>
        </slot>
      </footer>
    </slot>
  </Pane>
</template>
<script>
import Scrollable from './ScrollablePane.vue';
import Pane from './Pane.vue';

export default {
  components: { Pane, Scrollable },
  props: {
    scrollable: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
<style>
.dialog-action-pane {
  &__footer {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dimension-small);

    padding: var(--dimension-small) var(--dimension-medium);

    background-color: var(--color-background);
  }

  &__main {
    flex: 1;
  }

  &__buttons, &__actions {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;
    align-self: center;
  }

  &__buttons {
    flex: 1;
    justify-content: flex-end;
    align-self: center;
  }

}

@media screen and (max-width: 768px) {
  .dialog-action-pane {
    &__buttons, &__actions {
      flex-direction: column;
    }
  }
}
</style>
